import React from 'react';
import ContactTab from './Home/Components/Contact';

const Contact = () => {
  return (
    <>
      <ContactTab />
    </>
  )
}

export default Contact