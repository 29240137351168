import React from 'react';
import ServicesTab from './Home/Components/Services';

const Services = () => {
  return (
    <>
      <ServicesTab />
    </>
  )
}

export default Services