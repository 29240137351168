import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/vendor/aos/aos.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";
import "../style.css";

const Hero = () => {
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1>
            Welcome to <span>Gimble</span>
          </h1>
          <h2>
            We are team of talented designers making IT Solutions to help grow
            and scale businesses
          </h2>
          <div className="d-flex">
            <Link to="#about" className="btn-get-started scrollto">
              Get Started
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
